<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="font-weight-black subtitle-1">LAPORAN</span>
      </v-col>
    </v-row>

    <v-row>

      <v-col cols="12" sm="12" md="8">
        <DashboardSales></DashboardSales>
      </v-col>

    </v-row>


  </v-container>
 </template>

<script>
import {format, parseISO} from "date-fns";
import ERole from "@/models/e-role";
import FileService from "@/services/apiservices/file-service";
import DashboardSales from "@/components/laporan/reports/SalesReportDetil";

export default {
  name: "SalesReport",
  components: {DashboardSales},
  data(){
    return {
    }
  },
  watch:{
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
  },
  methods:{

    fetchParent() {
      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS, ERole.ROLE_AGEN, ERole.ROLE_RSL_1, ERole.ROLE_RSL_2].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.dispatch('data/loadFSalesmanChildrenAndMe')
      }else {
        this.$store.dispatch('data/loadFSalesman')
      }

      this.$store.dispatch('data/loadFDivision')

      this.$store.dispatch('data/loadFSalesman')
      this.$store.dispatch('data/loadFMaterial')
      // this.$store.dispatch('data/loadFStore')
      this.$store.dispatch('data/loadFWarehouse')
      this.$store.dispatch('data/loadFExpedisi')

      this.$store.dispatch('data/loadFRegion')
      this.$store.dispatch('data/loadFArea')
      this.$store.dispatch('data/loadFSubArea')

    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },

    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },

},
  mounted() {
    this.fetchParent()
    // this.fetchFtSalesh()
  }
}
</script>

<style scoped>

</style>